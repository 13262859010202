import React from 'react';
import cx from 'classnames';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { WaterColor } from '../components/WaterColor/WaterColor';
import { FeatureCard } from '../components/FeatureCard';

import styles from './others.module.scss';

const ProductsPage = () => (
  <Layout className={styles.page}>
    <SEO title="Products" />
    <div className="content">
      <div className="twoColumnLayout">
        <h1 className="title">
          Page Detains and Contents-coming soon
        </h1>
        <WaterColor color="green" />
      </div>
      <section className={cx(styles.cardWrapper)}>
        <FeatureCard
          heading="Students Project"
          bannerCopy="Featured Partner"
          isFeatured
        >
          <p>
            Loading....
          </p>
        </FeatureCard>
        <FeatureCard heading="Others">
          <p>
            <a
              href="https://medium.com/@gews"
              target="_blank"
              rel="noopener noreferrer"
            >
              Others
            </a>{' '}
          
            Loading... 
        
          </p>
        </FeatureCard>
        <FeatureCard heading="Our Community Hub">
          <p>
            Loading...
          </p>
        </FeatureCard>
      </section>
    </div>
    <div className={cx(styles.partnersFooter, 'content')}>
      <h2>Partner With Us!</h2>
      Are you impacted by our goal?? {' '}
      <a href="mailto:partner@gews.edu.gh?subject=Partnership">
        partner@gews.edu.gh
      </a>
      .
    </div>
  </Layout>
);

export default ProductsPage;
